<template>
  <div class="pb-5" :data-wizard-type="type" :data-wizard-state="state">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Maksimum katılımcı sayısı
    </h1>
    <p class="font-size-h5 mb-7">
      Etkinliğiniz için en uygun grup boyutunu düşünün. Küçük ve samimi mi olmalı? Büyük ve eğlenceli mi? 
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="form-group mb-10">
      <div class="input-group input-group-lg input-group-solid col-xl-6">
        <input
          placeholder="Katılımcı sayısı girin"
          type="number"
          class="form-control"
          v-model.number="maxParticipant"
          ref="inputMaxParticipant"
          @input="handleInputChange"
        />
        <div class="input-group-append">
          <div
              class="spinner spinner-dark mr-11"
              v-if="isSaving"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'ExperienceWizardMaxParticipant',
  props: ['experienceId', 'type', 'state'],
  data() {
    return {
      maxParticipant: '',
      error: {
        status: false,
        message: 'Devam edebilmek için lütfen katılımcı sayısı girin.',
      },
      isSaving: false,
      timeout: null,
    };
  },
  mounted() {
    this.getMaxParticipant();
  },
  methods: {
    getMaxParticipant() {
      ApiService.get(`experience/max-participant/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        if (data.maxParticipant === 0) {
          this.maxParticipant = '';
        } else {
          this.maxParticipant = data.maxParticipant;
        }
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    setMaxParticipant() {
      this.isSaving = true;
      const data = {
        id: this.experienceId,
        maxParticipant: this.maxParticipant
      }

      ApiService.post('experience/max-participant/set.req.php', data)
      .then(() => {
        this.isSaving = false;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    handleInputChange() {
      const self = this;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        self.setMaxParticipant();
      }, 1000);
    },

    validate() {
      if (this.maxParticipant === '' || this.maxParticipant === 0) {
        this.error.status = true;
        return false;
      } else {
        this.error.status = false;
        return true;
      }
    },
  },
};
</script>

<style scoped>
.form-group input[type='text'] {
  height: 60px;
}
</style>
