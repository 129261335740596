<template>
  <div class="pb-5" :data-wizard-type="type" :data-wizard-state="state">
    <div class="d-flex flex-row align-items-center justify-content-between mt-30">
      <div class="mr-10">
        <h1 class="mb-5 font-weight-bolder text-dark display-4">
          Deneyiminiz gönderime hazır!
        </h1>
        <p class="font-size-h5 mb-7">
          İstediğiniz zaman geri dönüp açıklamalarınıza ince ayarlar yapabilirsiniz.
        </p>
        
        <div>
          <button type="button" class="btn btn-dark btn-pill font-weight-bold px-9 py-4" @click="submit">
            <div class="d-flex align-items-center" v-if="!isSubmitting">
              <span class="label label-dot label-danger mr-2"></span>
              <span>Deneyimi Gönder</span>
            </div>
            <div class="d-flex align-items-center" v-else>
              <div class="spinner spinner-danger"></div>
              <span class="ml-8">Gönderiliyor...</span>
            </div>
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-center"><img class="w-50" src="media/3d/businessman-thumbs-up.png" alt=""></div>
    </div>
    
    <div class="alert alert-danger" role="alert" v-if="error.status">
      <div class="alert-text">{{ error.message }}</div>
    </div>
  
    
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'ExperienceWizardSubmit',
  props: ['experienceId', 'type', 'state'],
  data() {
    return {
      error: {
        status: false,
        message: '',
      },
      isSubmitting: false
    }
  },
  methods: {
    submit() {
      this.isSubmitting = true;
      setTimeout(() => {
        this.setStatus();
      }, 1000);
    },
    
    setStatus() {
      const data = {
        id: this.experienceId,
        status: 3
      }
      ApiService.post('experience/status/set.req.php', data)
      .then(() => {
        this.error.status = false;
        this.$router.push({ name: "show-experience", params: { id: this.experienceId } });
      })
      .catch(({ response }) => {
        this.isSubmitting = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });

    }
  }
}
</script>
