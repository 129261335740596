<template>
  <div class="pb-5" :data-wizard-type="type" :data-wizard-state="state">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Gitmeden önce bilinmesi gerekenler neler?
    </h1>
    <p class="font-size-h5 mb-7">
      Misafirlerin etkinliğe katılmadan önce bilmesi gereken önemli bilgilerin neler olduğunu açıklayın.
    </p>

    <div class="alert alert-danger" role="alert" v-if="error.status">
      <div class="alert-text">{{ error.message }}</div>
    </div>

    <div class="form-group d-flex flex-row justify-content-start align-items-center">
      <b-form-radio-group
        :options="languageOptions"
        v-model="selectedLanguage"
        buttons
        button-variant="outline-secondary"
        name="radios-btn-language"
      ></b-form-radio-group>
    </div>


    <div class="form-group mb-10" v-show="selectedLanguage === 'tr'">
      <ckeditor ref="importantTR" :editor="editor" :value="importants.tr" v-model="editorData.tr" :config="editorConfig" @input="onEditorInput"></ckeditor>
    </div>

    <div class="form-group mb-10" v-show="selectedLanguage === 'en'">
      <ckeditor ref="importantEN" :editor="editor" :value="importants.en" v-model="editorData.en" :config="editorConfig" @input="onEditorInput"></ckeditor>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import CKEditor from '@ckeditor/ckeditor5-vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
  name: "ExperienceWizardImportants",
  props: ['experienceId', 'type', 'state'],
  components: {
    ckeditor: CKEditor.component
  },
  data() {
    return {
      selectedLanguage: 'tr',
      languageOptions: [
        { text: 'Türkçe', value: 'tr' },
        { text: 'İngilizce', value: 'en' },
      ],
      editor: ClassicEditor,
      editorConfig: {
        placeholder: 'Önemli bilgileri anlatın',
        toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', 'blockQuote' ],
        heading: {
            options: [
                { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' }
            ]
        }
      },
      editorData: {
        tr: '',
        en: ''
      },
      importants: {
        tr: '',
        en: ''
      },
      error: {
        status: false,
        message: 'Devam edebilmek için lütfen bir hizmet türü seçin.',
      },
      timeout: null
    }
  },
  mounted() {
    this.getImportants();
  },
  methods: {
    getImportants() {
      ApiService.get(`experience/important/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.editorData.tr = data.importants.tr;
        this.editorData.en = data.importants.en;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    setImportants() {
      const data = {
        id: this.experienceId,
        importants: JSON.stringify({
          tr: this.$refs.importantTR.value,
          en: this.$refs.importantEN.value,
        })
      }

      ApiService.post('experience/important/set.req.php', data)
      .then(() => {
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    onEditorInput() {
      const self = this;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        self.setImportants();
      }, 1000);
    },

    validate() {
      return true;
    }
  }
}
</script>

<style>

</style>