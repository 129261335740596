<template>
  <div class="pb-5" :data-wizard-type="type" :data-wizard-state="state">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Deneyiminizin süresi ne kadar?
    </h1>
    <p class="font-size-h5 mb-7">
      Misafirlerinizin bu etkinlik için ne kadar zaman harcayacaklarını söyleyin.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="form-group" v-if="!duration.status">
      <div class="d-flex flex-row align-items-center option" @click="$bvModal.show('modal-add-duration-item')">
        <span class="option-label">
          <span class="d-flex flex-row align-items-center option-head">
            <div class="option-title pr-4 flex-fill">
              <span class="d-inline-flex align-items-center">
                <i class="flaticon2-plus-1 pr-8 text-body"></i> 
                <span class="font-size-h6 font-weight-bolder text-body">Süre ekle</span>
              </span>
            </div>
          </span>
        </span>
      </div>
    </div>

    <div v-else>
      <div class="form-group d-flex flex-row justify-content-between">
        <b-form-radio-group
          :options="languageOptions"
          v-model="selectedLanguage"
          buttons
          button-variant="outline-secondary"
          name="radios-btn-language"
        ></b-form-radio-group>
      </div>

      <div class="form-group">
        <div class="d-flex flex-row align-items-center option">
          <span class="option-label">
            <span class="d-flex flex-row align-items-center option-head">
              <div class="option-title">
                {{ duration.title[selectedLanguage] }}
              </div>
              <div class="option-focus">
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm mr-3"
                  data-toggle="tooltip"
                  title="Düzenle"
                  @click="openEditDurationModal()"
                >
                  <i class="flaticon2-edit icon-md"></i>
                </b-button>
              </div>
            </span>
            <span
              class="option-body text-dark-50 mw-80 pt-0"
              v-if="duration.description[selectedLanguage] !== ''"
            >
              {{ duration.description[selectedLanguage] }}
            </span>
          </span>
        </div>
      </div>
    </div>
    
    

    <!--Begin: Add duration modal-->
    <b-modal id="modal-add-duration-item" ref="modal-add-duration-item" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleAddItemModalCancel" @hide="handleAddItemModalCancel" @ok="handleAddItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ addItemModal.activeStep }} ADIM/2</label>
            <h5>{{ addItemModal.steps[addItemModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-if="addItemModal.activeStep === 1">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Süre</label>
            <input
              class="form-control form-control-lg mb-2"
              placeholder="Ör: 3 Saat, 1 Gece 2 Gün"
              ref="addDurationModalTR"
              v-model="addItemModal.duration.tr"
            >
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Açıklama (İsteğe bağlı)</label>
            <textarea
              class="form-control mb-2"
              rows="2"
              placeholder="Açıklamayı buraya yazın"
              ref="addDurationModalDescTR"
              v-model="addItemModal.durationDescription.tr"
            ></textarea>
          </div>
        </div>
        <div v-else>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Süre</label>
            <input
              class="form-control form-control-lg mb-2"
              placeholder="Ör: 3 Hours, 1 Night 2 Days"
              ref="addDurationModalEN"
              v-model="addItemModal.duration.en"
            >
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Açıklama (İsteğe bağlı)</label>
            <textarea
              class="form-control mb-2"
              rows="2"
              placeholder="Açıklamayı buraya yazın"
              ref="addDurationModalDescEN"
              v-model="addItemModal.durationDescription.en"
            ></textarea>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="addItemModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="addItemModal.activeStep = addItemModal.activeStep - 1" 
            v-if="addItemModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="addItemModal.duration.en == ''"
            v-if="addItemModal.activeStep == addItemModal.steps.length"
          >
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="addItemModal.duration.tr == ''"
            @click="addItemModal.activeStep = addItemModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add duration modal-->

    <!--Begin: Edit duration modal-->
    <b-modal id="modal-edit-duration-item" ref="modal-edit-duration-item" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleEditItemModalCancel" @hide="handleEditItemModalCancel" @ok="handleEditItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ editItemModal.activeStep }} ADIM/2</label>
            <h5>{{ editItemModal.steps[editItemModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-if="editItemModal.activeStep === 1">
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Süre</label>
            <input
              class="form-control form-control-lg mb-2"
              placeholder="Ör: 3 Saat, 1 Gece 2 Gün"
              ref="editDurationModalTR"
              v-model="editItemModal.duration.tr"
            >
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Açıklama (İsteğe bağlı)</label>
            <textarea
              class="form-control mb-2"
              rows="2"
              placeholder="Açıklamayı buraya yazın"
              ref="editDurationModalDescTR"
              v-model="editItemModal.durationDescription.tr"
            ></textarea>
          </div>
        </div>
        <div v-else>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Süre</label>
            <input
              class="form-control form-control-lg mb-2"
              placeholder="Ör: 3 Hours, 1 Night 2 Days"
              ref="editDurationModalEN"
              v-model="editItemModal.duration.en"
            >
          </div>
          <div class="form-group">
            <label class="text-body font-weight-bold pl-1">Açıklama (İsteğe bağlı)</label>
            <textarea
              class="form-control mb-2"
              rows="2"
              placeholder="Açıklamayı buraya yazın"
              ref="editDurationModalDescEN"
              v-model="editItemModal.durationDescription.en"
            ></textarea>
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="editItemModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="editItemModal.activeStep = editItemModal.activeStep - 1" 
            v-if="editItemModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="editItemModal.duration.en == ''"
            v-if="editItemModal.activeStep == editItemModal.steps.length"
          >
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="editItemModal.duration.tr == ''"
            @click="editItemModal.activeStep = editItemModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Edit duration modal-->

  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'ExperienceWizardDuration',
  props: ['experienceId', 'type', 'state'],
  data() {
    return {
      selectedLanguage: 'tr',
      languageOptions: [
        { text: 'Türkçe', value: 'tr' },
        { text: 'İngilizce', value: 'en' },
      ],
      duration: {
        status: false,
        title: {
          tr: '',
          en: ''
        },
        description: {
          tr: '',
          en: ''
        }
      },
      addItemModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Türkçe anlatın'
          },
          {
            number: 2,
            title: 'İngilizce anlatın'
          }
        ],
        duration: {
          tr: '',
          en: ''
        },
        durationDescription: {
          tr: '',
          en: ''
        }
      },
      editItemModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Türkçe anlatın'
          },
          {
            number: 2,
            title: 'İngilizce anlatın'
          }
        ],
        duration: {
          tr: '',
          en: ''
        },
        durationDescription: {
          tr: '',
          en: ''
        }
      },
      error: {
        status: false,
        message: 'Devam edebilmek için lütfen süre ekleyin.',
      },
    }
  },
  mounted() {
    this.getDuration();
  },
  methods: {
    getDuration() {
      ApiService.get(`experience/duration/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.duration.status = data.durationStatus == 1;
        this.duration.title.tr = data.duration.tr;
        this.duration.title.en = data.duration.en;
        this.duration.description.tr = data.durationDesc.tr;
        this.duration.description.en = data.durationDesc.en;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    setDuration(data) {
      ApiService.post('experience/duration/set.req.php', data)
      .then(() => {
        this.getDuration();
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    handleAddItemModalCancel() {
      this.addItemModal.activeStep = 1;
      this.addItemModal.duration = { tr: '', en: '' };
      this.addItemModal.durationDescription = { tr: '', en: '' };
    },

    handleAddItemModalOk() {
      const data = {
        id: this.experienceId,
        duration: JSON.stringify({
          tr: this.addItemModal.duration.tr,
          en: this.addItemModal.duration.en
        }),
        durationDesc: JSON.stringify({
          tr: this.addItemModal.durationDescription.tr,
          en: this.addItemModal.durationDescription.en
        }),
      }
      
      this.setDuration(data);
      
    },

    openEditDurationModal() {
      this.editItemModal.duration.tr = this.duration.title.tr;
      this.editItemModal.duration.en = this.duration.title.en;
      this.editItemModal.durationDescription.tr = this.duration.description.tr;
      this.editItemModal.durationDescription.en = this.duration.description.en;
      this.$bvModal.show('modal-edit-duration-item');
    },

    handleEditItemModalOk() {
      const data = {
        id: this.experienceId,
        duration: JSON.stringify({
          tr: this.editItemModal.duration.tr,
          en: this.editItemModal.duration.en
        }),
        durationDesc: JSON.stringify({
          tr: this.editItemModal.durationDescription.tr,
          en: this.editItemModal.durationDescription.en
        }),
      }

      this.setDuration(data);
    },

    handleEditItemModalCancel() {
      this.editItemModal.activeStep = 1;
      this.editItemModal.duration = { tr: '', en: '' };
      this.editItemModal.durationDescription = { tr: '', en: '' };
    },

    validate() {
      if (!this.duration.status) {
        this.error.status = true;
        return false;
      } else {
        this.error.status = false;
        return true;
      }
    }
  }
}
</script>

<style>

</style>