<template>
  <div>
    <div class="d-flex flex-row align-items-center justify-content-between mb-8" v-if="false">
      <h2 class="font-weight-bold my-2 mr-5">
        Deneyim Oluştur
      </h2>
      <div class="d-flex flex-row align-items-center">
      <a @click="$router.go(-1)" href="javascript:;" class="btn btn-secondary btn-fixed-height font-weight-bold px-2 px-lg-5 mr-2">
        <span class="d-none d-md-inline">Geri</span>
      </a>
      <router-link
        :to="{ name: 'experiences'}"
        v-slot="{ href }"
      >
        <a :href="href" class="btn btn-primary btn-fixed-height font-weight-bold px-2 px-lg-5">
          <span class="d-none d-md-inline">Deneyimler</span>
        </a>
      </router-link>
      </div>
    </div>
    <CreateExperienceWizard></CreateExperienceWizard>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import CreateExperienceWizard from "@/view/pages/experience/components/CreateExperienceWizard";

export default {
  name: "CreateExperience",
  components: {
    CreateExperienceWizard
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "CreateExperience" }]);
  },
}
</script>

<style>

</style>