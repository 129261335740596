<template>
  <div class="pb-5" :data-wizard-type="type" :data-wizard-state="state">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Ne tür bir deneyime ev sahipliği yapacaksınız?
    </h1>
    <p class="font-size-h5 mb-7">
      Misafirlerin deneyiminiz sırasında genel olarak ne yapacağını en iyi
      şekilde açıklayan temayı seçin.
    </p>
    
    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>
    
    <div class="row">
      <div class="form-group col-xl-6" v-for="theme in themes" :key="theme.title">
        <label
          class="option selectable"
          :class="{ selected: theme.value == selectedTheme }"
        >
          <span class="option-control">
            <span class="radio radio-dark">
              <input
                type="radio"
                name="theme_option"
                :value="theme.value"
                v-model="selectedTheme"
                @change="themeSelected"
              />
              <span></span>
            </span>
          </span>
          <span class="option-label">
            <span class="option-head">
              <span class="option-title">
                {{ theme.title }}
              </span>
              <span class="option-focus">
                <div
                  class="spinner spinner-dark mr-4 mt-3"
                  v-if="theme.value == selectedTheme && isSaving"
                ></div>
              </span>
            </span>
          </span>
        </label>
      </div>
    </div>

    
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'ExperienceWizardTheme',
  props: ['experienceId', 'type', 'state'],
  data() {
    return {
      selectedTheme: 0,
      themes: [
        {
          title: 'Günübirlik Tur',
          value: 1,
          category: 1,
          accommodation: 0
        },
        {
          title: 'Otel Konaklamalı Tur',
          value: 2,
          category: 1,
          accommodation: 1
        },
        {
          title: 'Apart Konaklamalı Tur',
          value: 3,
          category: 1,
          accommodation: 2
        },
        {
          title: 'Aktivite',
          value: 4,
          category: 2,
          accommodation: 0
        },
        {
          title: 'Transfer',
          value: 5,
          category: 3,
          accommodation: 0
        },
        {
          title: 'Bilet',
          value: 6,
          category: 4,
          accommodation: 0
        },
      ],
      error: {
        status: false,
        message: 'Devam edebilmek için lütfen bir tema seçin.',
      },
      isSaving: false,
    };
  },
  mounted() {
    this.getTheme();
  },
  methods: {
    getTheme() {
      ApiService.get(`experience/theme/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.selectedTheme = data.theme;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.selectedTheme = 0;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    themeSelected() {
      this.isSaving = true;
      setTimeout(() => {
        this.setTheme();  
      }, 250);
    },

    setTheme() {
      const theme = this.selectedTheme;
      const item = this.themes.filter(function(el) {
        return el.value == theme; 
      })[0];
      const data = JSON.stringify({
        id: this.experienceId,
        theme: this.selectedTheme,
        category: item.category,
        accommodation: item.accommodation
      });

      ApiService.post('experience/theme/set.req.php', data)
      .then(() => {
        this.error.status = false;
        this.isSaving = false;
      })
      .catch(({ response }) => {
        this.selectedTheme = 0;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        this.isSaving = false;
      });
    },

    validate() {
      if (this.selectedTheme === 0) {
        this.error.status = true;
        return false;
      } else {
        this.error.status = false;
        return true;
      }
    },
  },
};
</script>
