<template>
  <div class="pb-5" :data-wizard-type="type" :data-wizard-state="state">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Neler dahil değil?
    </h1>
    <p class="font-size-h5 mb-7">
      Misafirlerin ödedikleri ücrete dahil olmayan hizmetleri ekleyin.
    </p>

    <div class="alert alert-danger" role="alert" v-if="error.status">
      <div class="alert-text">{{ error.message }}</div>
    </div>

    <div class="form-group d-flex flex-row justify-content-between">
      <button type="button" class="btn btn-hover-transparent-dark font-weight-bold d-inline-flex align-items-center" @click="$bvModal.show('modal-add-exclude-item')">
        <i class="flaticon2-add-1 icon-sm"></i>
        <span v-if="excludes.length == 0">Bir öğe ekle</span>
        <span v-else>Başka bir öğe ekle</span>
      </button>
      <b-form-radio-group
        :options="languageOptions"
        v-model="selectedLanguage"
        buttons
        button-variant="outline-secondary"
        name="radios-btn-language"
        v-if="excludes.length > 0"
      ></b-form-radio-group>
    </div>

    <div class="d-flex justify-content-center min-h-100px" v-if="isSaving">
      <div class="spinner spinner-dark"></div>
    </div>

    <div v-else>
      <div
        class="form-group"
        v-for="(exclude, index) in excludes"
        :key="index"
      >
        <div class="d-flex flex-row align-items-center option">
          <span class="option-label">
            <span class="d-flex flex-row align-items-center option-head">
              <div class="option-title pr-4 flex-fill">
                {{ exclude[selectedLanguage] }}
              </div>
              <div class="option-focus d-flex flex-fill justify-content-end">
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm mr-3"
                  data-toggle="tooltip"
                  title="Düzenle"
                  @click="openEditExcludeModal(index)"
                >
                  <i class="flaticon2-edit icon-md"></i>
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm"
                  data-toggle="tooltip"
                  title="Kaldır"
                  @click="removeExclude(index)"
                >
                  <i class="flaticon2-trash icon-md"></i>
                </b-button>
              </div>
            </span>
          </span>
        </div>
      </div>
    </div>

    <!--Begin: Add item modal-->
    <b-modal id="modal-add-exclude-item" ref="modal-add-exclude-item" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleAddItemModalCancel" @hide="handleAddItemModalCancel" @ok="handleAddItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Hizmet ekle</h5>
          </div>
        </div>
      </template>
      
      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">Türkçe anlatın</label>
        <textarea
          class="form-control mb-2"
          rows="2"
          placeholder="Açıklamayı buraya yazın"
          :maxlength="maxExcludeLength"
          ref="addExcludeModalTR"
          v-model="addItemModal.exclude.tr"
        ></textarea>
        <span class="text-muted pl-1">
          {{ addItemModal.exclude.tr.length }} / {{ maxExcludeLength }}
        </span>
      </div>
      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">İngilizce anlatın</label>
        <textarea
          class="form-control mb-2"
          rows="2"
          placeholder="Açıklamayı buraya yazın"
          :maxlength="maxExcludeLength"
          ref="addExcludeModalEN"
          v-model="addItemModal.exclude.en"
        ></textarea>
        <span class="text-muted pl-1">
        {{ addItemModal.exclude.en.length }} / {{ maxExcludeLength }}
        </span>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="addItemModalIsDisabled"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Edit new item modal-->

    <!--Begin: Edit item modal-->
    <b-modal id="modal-edit-exclude-item" ref="modal-edit-exclude-item" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleEditItemModalCancel" @hide="handleEditItemModalCancel" @ok="handleEditItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-2">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <h5>Hizmeti düzenle</h5>
          </div>
        </div>
      </template>
      
      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">Türkçe anlatın</label>
        <textarea
          class="form-control mb-2"
          rows="2"
          placeholder="Açıklamayı buraya yazın"
          :maxlength="maxExcludeLength"
          ref="editExcludeModalTR"
          v-model="editItemModal.exclude.tr"
        ></textarea>
        <span class="text-muted pl-1">
          {{ editItemModal.exclude.tr.length }} / {{ maxExcludeLength }}
        </span>
      </div>
      <div class="form-group">
        <label class="text-body font-weight-bold pl-1">İngilizce anlatın</label>
        <textarea
          class="form-control mb-2"
          rows="2"
          placeholder="Açıklamayı buraya yazın"
          :maxlength="maxExcludeLength"
          ref="editExcludeModalEN"
          v-model="editItemModal.exclude.en"
        ></textarea>
        <span class="text-muted pl-1">
        {{ editItemModal.exclude.en.length }} / {{ maxExcludeLength }}
        </span>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="editItemModalIsDisabled"
            @click="ok"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Edit new item modal-->
    
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'ExperienceWizardExclude',
  props: ['experienceId', 'type', 'state'],
  data() {
    return {
      excludes: [],
      selectedLanguage: 'tr',
      languageOptions: [
        { text: 'Türkçe', value: 'tr' },
        { text: 'İngilizce', value: 'en' },
      ],
      addItemModal: {
        exclude: {
          tr: '',
          en: ''
        }
      },
      editItemModal: {
        indexOfSelecteExclude: null,
        exclude: {
          tr: '',
          en: ''
        }
      },
      maxExcludeLength: 100,
      error: {
        status: false,
        message: 'Devam edebilmek için lütfen bir hizmet türü seçin.',
      },
      isSaving: false
    }
  },
  computed: {
    addItemModalIsDisabled() {
      if (
        this.addItemModal.exclude.tr.length === 0 ||
        this.addItemModal.exclude.en.length === 0) {
        return true;
      }
      return false;
    },
    
    editItemModalIsDisabled() {
      if (
        this.editItemModal.exclude.tr.length === 0 ||
        this.editItemModal.exclude.en.length === 0) {
        return true;
      }
      return false;
    }
  },
  mounted() {
    this.getExcludes();
  },
  methods: {
    getExcludes() {
      ApiService.get(`experience/exclude/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.excludes = data.excludes;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    setExcludes(excludes) {
      const data = {
        id: this.experienceId,
        excludes: JSON.stringify(excludes)
      }

      this.isSaving = true;
      ApiService.post('experience/exclude/set.req.php', data)
      .then(() => {
        this.getExcludes();
        this.isSaving = false;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    handleAddItemModalCancel() {
      this.addItemModal.exclude.tr = '';
      this.addItemModal.exclude.en = '';
    },
    handleEditItemModalCancel() {
      this.editItemModal.indexOfSelecteExclude = null;
      this.editItemModal.exclude.tr = '';
      this.editItemModal.exclude.en = '';
    },
    handleAddItemModalOk() {
      const newExclude = {
        tr: this.$refs.addExcludeModalTR.value,
        en: this.$refs.addExcludeModalEN.value
      };
      let excludesCopy = [...this.excludes];
      excludesCopy.push(newExclude);

      this.setExcludes(excludesCopy);
      
    },

    openEditExcludeModal(index) {
      const exclude = this.excludes[index];

      this.editItemModal.indexOfSelecteExclude = index;
      this.editItemModal.exclude.tr = exclude.tr;
      this.editItemModal.exclude.en = exclude.en;
      this.$bvModal.show('modal-edit-exclude-item');
    },

    handleEditItemModalOk() {
      const index = this.editItemModal.indexOfSelecteExclude;
      const newExclude = {
        tr: this.$refs.editExcludeModalTR.value,
        en: this.$refs.editExcludeModalEN.value
      };
      let excludesCopy = [...this.excludes];
      excludesCopy[index] = newExclude;

      this.setExcludes(excludesCopy);
      
    },

    removeExclude(index) {
      let excludesCopy = [...this.excludes];
      excludesCopy.splice(index, 1);
      this.setExcludes(excludesCopy);
    },

    validate() {
      return true;
    }
  }
}
</script>

<style>

</style>