<template>
  <div class="pb-5" :data-wizard-type="type" :data-wizard-state="state">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Deneyimi birkaç cümle ile anlat
    </h1>
    <p class="font-size-h5 mb-7">
      Bu deneyimde misafirleri nelerin beklediğini çok fazla detaya inmeden
      anlatabilirsiniz.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="form-group mb-10">
      <label class="text-body font-weight-bold pl-1">Türkçe anlatın</label>
      <textarea
        class="form-control form-control-solid font-size-lg mb-2"
        rows="4"
        placeholder="Misafirleri bu deneyimde neler bekliyor?"
        :maxlength="maxSummaryLength"
        v-model.trim="summary.tr"
        @input="handleInputChange"
      ></textarea>
      <span class="text-muted pl-1">
        {{ summary.tr.length }} / {{ maxSummaryLength }}
      </span>
    </div>

    <div class="form-group">
      <label class="text-body font-weight-bold pl-1">İngilizce anlatın</label>
      <textarea
        class="form-control form-control-solid font-size-lg mb-2"
        rows="4"
        placeholder="Misafirleri bu deneyimde neler bekliyor?"
        :maxlength="maxSummaryLength"
        v-model.trim="summary.en"
        @input="handleInputChange"
      ></textarea>
      <span class="text-muted pl-1">
        {{ summary.en.length }} / {{ maxSummaryLength }}
      </span>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'ExperienceWizardSummary',
  props: ['experienceId', 'type', 'state'],
  data() {
    return {
      summary: {
        tr: '',
        en: '',
      },
      error: {
        status: false,
        message: 'Lütfen deneyiminizi birkaç cümle ile anlatın.',
      },
      isSaving: false,
      maxSummaryLength: 300,
    };
  },
  mounted() {
    this.getSummary();
  },
  methods: {
    getSummary() {
      ApiService.get(`experience/summary/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.summary.tr = data.summary.tr;
        this.summary.en = data.summary.en;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    setSummary() {
      const data = JSON.stringify({
        id: this.experienceId,
        summary: JSON.stringify(this.summary)
      });

      ApiService.post('experience/summary/set.req.php', data)
      .then(() => {
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    handleInputChange() {
      const self = this;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        self.setSummary();
      }, 1000);
    },

    validate() {
      if (this.summary.tr === '' || this.summary.en === '') {
        this.error.status = true;
        return false;
      } else {
        this.error.status = false;
        return true;
      }
    },
  },
};
</script>

<style></style>
