<template>
  <div class="pb-5" :data-wizard-type="type" :data-wizard-state="state">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Kimler bu deneyime katılabilir?
    </h1>
    <p class="font-size-h5 mb-7">
      Deneyime katılabilecek yolcu tiplerini seçin.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="form-group d-flex flex-row justify-content-between">
      <button type="button" class="btn btn-hover-transparent-dark font-weight-bold d-inline-flex align-items-center" @click="$bvModal.show('modal-add-participant-item')">
        <i class="flaticon2-add-1 icon-sm"></i>
        <span v-if="selectedParticipants.length == 0">Bir öğe ekle</span>
        <span v-else>Başka bir öğe ekle</span>
      </button>
    </div>

    <div class="d-flex justify-content-center min-h-100px" v-if="isSaving">
      <div class="spinner spinner-dark"></div>
    </div>
  
    <div v-else>
      <div
        class="form-group"
        v-for="(participant, index) in selectedParticipants"
        :key="index"
      >
        <div class="d-flex flex-row align-items-center option">
          <span class="option-control mr-4" style="width: auto;">
            <div class="symbol">
              <span class="symbol-label">
                <inline-svg :src="participantDetails[participant.id].avatar" />
              </span>
            </div>
          </span>
          <span class="option-label">
            <span class="d-flex flex-row align-items-center option-head">
              <div class="option-title">
                {{ participantDetails[participant.id].title }}
              </div>
              <div class="option-focus">
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm mr-3"
                  data-toggle="tooltip"
                  title="Düzenle"
                  v-if="participant.id != 'passenger' "
                  @click="openEditParticipantModal(participant.id)"
                >
                  <i class="flaticon2-edit icon-md"></i>
                </b-button>
                <b-button
                  v-b-tooltip.hover
                  type="button"
                  class="btn btn-icon btn-light btn-sm"
                  data-toggle="tooltip"
                  title="Kaldır"
                  @click="removeSelectedParticipant(participant.id)"
                >
                  <i class="flaticon2-trash icon-md"></i>
                </b-button>
              </div>
            </span>
            <span class="option-body text-dark-50 mw-80 pt-0">
              {{ participant.age }}
            </span>
          </span>
        </div>
      </div>
    </div>

    <!--Begin: Add new item modal-->
    <b-modal id="modal-add-participant-item" ref="modal-add-participant-item" no-fade centered scrollable header-class="py-3" footer-class="py-3" @cancel="handleAddItemModalCancel" @hide="handleAddItemModalCancel" @ok="handleAddItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ addItemModal.activeStep }} ADIM/2</label>
            <h5>{{ addItemModal.steps[addItemModal.activeStep - 1].title }}</h5>
          </div>
        </div>
      </template>
      
      <div class="max-h-300px">
        <div v-if="addItemModal.activeStep === 1">
          <div class="d-flex flex-wrap ">
            <div class="flex-column align-items-center justify-content-center p-4 option selectable mb-10 option-sml" :class="[{selected: addItemModal.selectedItemId === item.id}, {'mr-15': (index+1) % 3 != 0}]" v-for="(item,index) in participants" :key="index" @click="addItemModal.selectedItemId = item.id">
              <span class="option-control mb-2" style="width: auto;">
                <div class="symbol">
                  <span class="symbol-label">
                    <inline-svg :src="participantDetails[item.id].avatar" />
                  </span>
                </div>
              </span>
              <span class="option-label">
                <span class="option-head justify-content-center">
                  <div class="option-title">
                    {{ participantDetails[item.id].title }}
                  </div>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="form-group" v-if="addItemModal.selectedItemId == 'passenger'">
            <div class="alert alert-secondary" role="alert">
              <div class="alert-text">Seçmiş olduğunuz katılımcı tipi için yaş aralığı istenmemektedir. Lütfen Kaydet diyerek devam edin.</div>
            </div>
          </div>
          <div class="form-group" v-show="addItemModal.selectedItemId != 'passenger'">
            <input
              type="text"
              class="form-control form-control-lg"
              placeholder="Geçerli yaş aralığı"
              ref="addParticipantModalAge"
              v-model.trim="addItemModal.selectedItemAge"
            >
          </div>
        </div>
        <div class="pb-4"></div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel" 
            v-if="addItemModal.activeStep == 1"
          >İptal et</button>
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="addItemModal.activeStep = addItemModal.activeStep - 1" 
            v-if="addItemModal.activeStep > 1"
          ><i class="ki ki-arrow-back"></i>Geri</button>

          <button 
            type="button"
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok" 
            :disabled="addItemModal.selectedItemAge == '' && addItemModal.selectedItemId != 'passenger' "
            v-if="addItemModal.activeStep == addItemModal.steps.length"
          >
            Kaydet
          </button>
          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            :disabled="addItemModal.selectedItemId == null"
            @click="addItemModal.activeStep = addItemModal.activeStep + 1"
            v-else
          >
            Sonraki
            <i class="ki ki-arrow-next"></i>
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Add new item modal-->

    <!--Begin: Edit item modal-->
    <b-modal id="modal-edit-participant-item" ref="modal-edit-participant-item" no-fade centered header-class="py-3" footer-class="py-3" @cancel="handleEditItemModalCancel" @hide="handleEditItemModalCancel" @ok="handleEditItemModalOk">
      <template #modal-header="{ cancel }">
        <button type="button" @click="cancel" class="btn btn-icon btn-hover-light btn-sm"><i class="ki ki-close icon-nm text-dark"></i></button>
        <div class="d-flex flex-fill align-items-center justify-content-center pt-0">
          <div class="d-flex flex-column align-items-center" style="margin-left: -32px;">
            <label>{{ editItemModal.selectedItemTitle }}</label>
            <h5>Yaş aralığı girin</h5>
          </div>
        </div>
      </template>
      
      <div class="form-group">
        <input
          type="text"
          class="form-control form-control-lg"
          placeholder="Geçerli yaş aralığı"
          ref="editParticipantModalAge"
          v-model.trim="editItemModal.selectedItemAge"
        >
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="d-flex flex-row align-items-center justify-content-between flex-fill">
          <button 
            type="button" 
            class="btn btn-hover-transparent-dark font-weight-bolder py-4" 
            @click="cancel"
          >İptal et</button>

          <button 
            type="button" 
            class="btn btn-dark font-weight-bolder px-5 py-4" 
            @click="ok"
            :disabled="editItemModal.selectedItemAge == ''"
          >
            Kaydet
          </button>
        </div>
      </template>

    </b-modal>
    <!--End: Edit new item modal-->


  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'ExperienceWizardParticipant',
  props: ['experienceId', 'type', 'state'],
  data() {
    return {
      participants: [],
      selectedParticipants: [],
      participantDetails: {
        passenger: {
          title: "Yolcu",
          avatar: "/media/svg/participants/passenger.svg"
        },
        elderly: {
          title: "Yaşlı",
          avatar: "/media/svg/participants/elderly.svg"
        },
        adult: {
          title: "Yetişkin",
          avatar: "/media/svg/participants/adult.svg"
        },
        youth: {
          title: "Genç",
          avatar: "/media/svg/participants/youth.svg"
        },
        student: {
          title: "Öğrenci",
          avatar: "/media/svg/participants/student.svg"
        },
        child: {
          title: "Çocuk",
          avatar: "/media/svg/participants/child.svg"
        },
        child_2: {
          title: "Çocuk 2",
          avatar: "/media/svg/participants/child_2.svg"
        },
        infant: {
          title: "Bebek",
          avatar: "/media/svg/participants/infant.svg"
        },
      },
      addItemModal: {
        activeStep: 1,
        steps: [
          {
            number: 1,
            title: 'Yolcu tipini seçin'
          },
          {
            number: 2,
            title: 'Yaş aralığı girin'
          }
        ],
        selectedItemId: null,
        selectedItemAge: ''
      },
      editItemModal: {
        selectedItemId: null,
        selectedItemTitle: '',
        selectedItemAge: ''
      },
      error: {
        status: false,
        message: 'Devam edebilmek için lütfen en az bir adet katılımcı ekleyin.',
      },
      isSaving: false
    };
  },
  mounted() {
    this.getParticipants();
  },
  methods: {
    getParticipants() {
      ApiService.get(`experience/participant/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.participants = data.participants;
        this.selectedParticipants = data.selectedParticipants;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    setParticipants(data) {
      this.isSaving = true;
      ApiService.post('experience/participant/set.req.php', data)
      .then(() => {
        this.getParticipants();
        this.error.status = false;
        this.isSaving = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        this.isSaving = false;
      });
    },

    handleAddItemModalCancel() {
      this.addItemModal.activeStep = 1;
      this.addItemModal.selectedItemId = null;
      this.addItemModal.selectedItemAge = '';
    },

    handleAddItemModalOk() {
      const participant = this.addItemModal.selectedItemId;

      const data = {
        id: this.experienceId,
        participant: participant,
        age: this.$refs.addParticipantModalAge.value
      }
      
      this.setParticipants(data);
      
    },

    openEditParticipantModal(id) {
      const participant = this.selectedParticipants.filter(function(el) {
        return el.id == id;
      })[0];

      this.editItemModal.selectedItemId = id;
      this.editItemModal.selectedItemTitle = this.participantDetails[id].title;
      this.editItemModal.selectedItemAge = participant.age;
      this.$bvModal.show('modal-edit-participant-item');
    },

    handleEditItemModalOk() {
      const participant = this.editItemModal.selectedItemId;

      const data = {
        id: this.experienceId,
        participant: participant,
        age: this.$refs.editParticipantModalAge.value
      };

      this.setParticipants(data);
    },

    handleEditItemModalCancel() {
      this.editItemModal.selectedItemId = null;
      this.editItemModal.selectedItemAge = '';
    },

    removeSelectedParticipant(participant) {
      const data = {
        id: this.experienceId,
        participant: participant,
      };

      this.isSaving = true;
      ApiService.post('experience/participant/remove.req.php', data)
      .then(() => {
        this.getParticipants();
        this.error.status = false;
        this.isSaving = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        this.isSaving = false;
      });
    },

    validate() {
      if (this.selectedParticipants.length == 0) {
        this.error.status = true;
        return false;
      } else {
        this.error.status = false;
        return true;
      }
    }
  },
};
</script>

<style scoped>
.option {
  cursor: initial;
}
.option.selectable {
  cursor: pointer;
}
.option-sml {
  flex: 0 0 25%;
}
</style>
