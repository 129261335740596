<template>
  <div class="pb-5" :data-wizard-type="type" :data-wizard-state="state">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Ne tür bir hizmet sunacaksınız?
    </h1>
    <p class="font-size-h5 mb-7">
      Misafirlerinize deneyiminiz sırasında nasıl bir hizmet sunacağınızı en iyi
      şekilde açıklayan hizmet türünü seçin.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="form-group" v-for="service in services" :key="service.title">
      <label
        class="option selectable"
        :class="{ selected: service.value == selectedServiceType }"
      >
        <span class="option-control">
          <span class="radio radio-dark">
            <input
              type="radio"
              name="service_type_option"
              :value="service.value"
              v-model="selectedServiceType"
              @change="serviceTypeSelected"
            />
            <span></span>
          </span>
        </span>
        <span class="option-label">
          <span class="option-head">
            <span class="option-title">
              {{ service.title }}
            </span>
            <span class="option-focus">
              <div
                class="spinner spinner-dark mr-4 mt-3"
                v-if="service.value == selectedServiceType && isSaving"
              ></div>
            </span>
          </span>
          <span class="option-body text-dark-50">
            {{ service.description }}
          </span>
        </span>
      </label>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'ExperienceWizardServiceType',
  props: ['experienceId', 'type', 'state'],
  data() {
    return {
      selectedServiceType: 0,
      services: [
        {
          title: 'SIC',
          description: 'Kişi başı fiyatlandırma ve paylaşımlı araçla seyahat.',
          value: 1,
        },
        {
          title: 'Private',
          description: 'Grup başı fiyatlandırma ve özel araçla seyahat.',
          value: 2,
        },
      ],
      error: {
        status: false,
        message: 'Devam edebilmek için lütfen bir hizmet türü seçin.',
      },
      isSaving: false,
    };
  },
  mounted() {
    this.getServiceType();
  },
  methods: {
    getServiceType() {
      ApiService.get(`experience/service-type/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.selectedServiceType = data.serviceType;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.serviceType = 0;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    serviceTypeSelected() {
      this.isSaving = true;
      setTimeout(() => {
        this.setServiceType();  
      }, 250);
    },

    setServiceType() {
      const data = JSON.stringify({
        id: this.experienceId,
        serviceType: this.selectedServiceType
      });

      ApiService.post('experience/service-type/set.req.php', data)
      .then(() => {
        this.error.status = false;
        this.isSaving = false;
      })
      .catch(({ response }) => {
        this.selectedServiceType = 0;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        this.isSaving = false;
      });
    },

    validate() {
      if (this.selectedServiceType === 0) {
        this.error.status = true;
        return false;
      } else {
        this.error.status = false;
        return true;
      }
    },
  },
};
</script>
