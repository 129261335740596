<template>
  <div class="pb-5" :data-wizard-type="type" :data-wizard-state="state">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Hareket yeri
    </h1>
    <p class="font-size-h5 mb-7">
      Deneyiminiz hangi destinasyondan hareket edecek?
    </p>
    
    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>
    
    <div class="row">
      <div class="form-group col-xl-6" v-for="destination in destinations" :key="destination.id">
        <label
          class="option selectable"
          :class="{ selected: destination.id == selectedDestination }"
        >
          <span class="option-control">
            <span class="radio radio-dark">
              <input
                type="radio"
                name="theme_option"
                :value="destination.id"
                v-model="selectedDestination"
                @change="destinationSelected"
              />
              <span></span>
            </span>
          </span>
          <span class="option-label">
            <span class="option-head">
              <span class="option-title">
                {{ destination.name }}
              </span>
              <span class="option-focus">
                <div
                  class="spinner spinner-dark mr-4 mt-3"
                  v-if="destination.id == selectedDestination && isSaving"
                ></div>
              </span>
            </span>
          </span>
        </label>
      </div>
    </div>

    
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'ExperienceWizardDeparture',
  props: ['experienceId', 'type', 'state'],
  data() {
    return {
      selectedDestination: 0,
      destinations: [],
      error: {
        status: false,
        message: 'Devam edebilmek için lütfen bir hareket yeri seçin.',
      },
      isSaving: false,
    }
  },
  mounted() {
    this.getDestinations();
  },
  methods: {
    getDestinations() {
      ApiService.get('destination/getActiveDestinations.req.php')
      .then(({ data }) => {
        this.destinations = data.destinations;
        this.error.status = false;
        this.getDeparturePoint();
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    getDeparturePoint() {
      ApiService.get(`experience/departure/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.selectedDestination = data.departurePoint;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.selectedDestination = 0;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    setDeparturePoint() {
      const data = JSON.stringify({
        id: this.experienceId,
        departurePoint: this.selectedDestination
      });

      ApiService.post('experience/departure/set.req.php', data)
      .then(() => {
        this.error.status = false;
        this.isSaving = false;
      })
      .catch(({ response }) => {
        this.selectedDestination = 0;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
        this.isSaving = false;
      });
    },

    destinationSelected() {
      this.isSaving = true;
      setTimeout(() => {
        this.setDeparturePoint();  
      }, 250);
    },

    validate() {
      if (this.selectedDestination === 0) {
        this.error.status = true;
        return false;
      } else {
        this.error.status = false;
        return true;
      }
    },
  }
}
</script>

<style>

</style>