<template>
  <div class="card card-custom card-shadowless">
    <div class="card-body p-0">
      <!--begin: Wizard-->
      <div
        class="wizard wizard-2"
        id="kt_wizard_experience"
        data-wizard-state="step-first"
        data-wizard-clickable="false"
      >
        <!--begin: Wizard Nav -->
        <div class="wizard-nav bg-light pt-8 px-8 px-lg-10" id="experience_builder_wizard_nav">
          <div class="d-flex align-items-bottom justify-content-between">
            <router-link to="/">
              <img
                alt="Logo"
                :src="layoutConfig('self.logo.default')"
                class="logo-sticky max-h-35px"
              />
            </router-link>
            <router-link to="/">
              <button class="btn btn-link text-dark font-weight-bold px-0">Kaydet ve çık</button>
            </router-link>
          </div>
          <div class="wizard-steps py-5 py-lg-5">
            <div
              class="wizard-step"
              :data-wizard-type="step.type"
              :data-wizard-state="step.state"
              v-for="step in steps"
              :key="step.number"
              @click="wizardNavClicked(step)"
            >
              <div class="wizard-wrapper">
                <div class="wizard-label">
                  <label class="checkbox checkbox-dark" :class="{'checkbox-outline': !step.isDone}">
                    <input
                      type="checkbox"
                      :checked="step.isDone"
                      disabled="disabled"
                    />
                    <span></span>
                    <h3 class="wizard-title font-weight-bold ml-4 mb-0 my-1">
                      {{ step.title }}
                    </h3>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end: Wizard Nav -->

        <!--begin: Wizard Body -->
        <div class="wizard-body py-8 px-8 py-lg-20 px-lg-10" id="experience_builder_wizard_body" lazy>
          <!--begin: Wizard Form-->
          <div class="row">
            <div class="offset-xxl-2 col-xxl-7">
              <form class="form" id="kt_form">
                <!--begin: Wizard Step 1-->
                <div>
                <Theme
                  state="current"
                  type="step-content"
                  ref="themeStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 1"
                ></Theme>
                <!--end: Wizard Step 1-->

                <!--begin: Wizard Step 2-->
                <ServiceType
                  state="current"
                  type="step-content"
                  ref="serviceTypeStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 2"
                ></ServiceType>
                <!--end: Wizard Step 2-->

                <!--begin: Wizard Step 2-->
                <Departure
                  state="current"
                  type="step-content"
                  ref="departureStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 3"
                ></Departure>
                <!--end: Wizard Step 2-->

                <!--begin: Wizard Step 3-->
                <Title
                  state="current"
                  type="step-content"
                  ref="titleStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 4"
                ></Title>
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Step 3-->
                <Summary
                  state="current"
                  type="step-content"
                  ref="summaryStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 5"
                ></Summary>
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Step 3-->
                <Provide
                  state="current"
                  type="step-content"
                  ref="provideStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 6"
                ></Provide>
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Step 3-->
                <Highlight
                  state="current"
                  type="step-content"
                  ref="highlightStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 7"
                ></Highlight>
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Step 3-->
                <FullDescription
                  state="current"
                  type="step-content"
                  ref="fullDescriptionStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 8"
                ></FullDescription>
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Step 3-->
                <Include
                  state="current"
                  type="step-content"
                  ref="includeStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 9"
                ></Include>
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Step 3-->
                <Exclude
                  state="current"
                  type="step-content"
                  ref="excludeStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 10"
                ></Exclude>
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Step 3-->
                <Important
                  state="current"
                  type="step-content"
                  ref="importantStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 11"
                ></Important>
                <!--end: Wizard Step 3-->
                
                <!--begin: Wizard Step 3-->
                <Photos
                  state="current"
                  type="step-content"
                  ref="photosStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 12"
                ></Photos>
                <!--end: Wizard Step 3-->

                 <!--begin: Wizard Step 3-->
                <MaxParticipant
                  state="current"
                  type="step-content"
                  ref="maxParticipantStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 13"
                ></MaxParticipant>
                <!--end: Wizard Step 3-->

                 <!--begin: Wizard Step 3-->
                <Participant
                  state="current"
                  type="step-content"
                  ref="participantStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 14"
                ></Participant>
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Step 3-->
                <Duration
                  state="current"
                  type="step-content"
                  ref="durationStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 15"
                ></Duration>
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Step 3-->
                <Price
                  state="current"
                  type="step-content"
                  ref="priceStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 16"
                ></Price>
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Step 3-->
                <Meeting
                  state="current"
                  type="step-content"
                  ref="meetingStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 17"
                ></Meeting>
                <!--end: Wizard Step 3-->

                <!--begin: Wizard Step 3-->
                <Submit
                  state="current"
                  type="step-content"
                  ref="submitStep"
                  :experience-id="experienceId"
                  v-if="activeStep == 18"
                ></Submit>
                <!--end: Wizard Step 3-->

                </div>

                <!--begin: Wizard Actions -->
                <div class="justify-content-between border-top pt-10" :class="{'d-none': activeStep == 18, 'd-flex': activeStep != 18}">
                  <div class="mr-2">
                    <button
                      class="btn btn-secondary font-weight-bold px-9 py-4"
                      data-wizard-type="action-prev"
                    >
                      Geri
                    </button>
                  </div>
                  <div>
                    <!--
                    <button
                      class="btn btn-primary font-weight-bold px-9 py-4"
                      ref="kt_create_category"
                      data-wizard-type="action-submit"
                    >
                      Kaydet
                    </button>
                    -->
                    <button
                      class="btn btn-dark font-weight-bolder px-9 py-4"
                      data-wizard-type="action-next"
                    >
                      Sonraki Adım
                    </button>
                  </div>
                </div>
                <!--end: Wizard Actions -->
              </form>
            </div>
            <!--end: Wizard-->
          </div>
        </div>
        <!--end: Wizard Body -->
      </div>
      <!--end: Wizard-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import KTWizard from '@/assets/js/components/wizard';
import KTUtil from '@/assets/js/components/util';
import ApiService from '@/core/services/api.service';

import Theme from '@/view/pages/experience/components/wizard/Theme';
import ServiceType from '@/view/pages/experience/components/wizard/ServiceType';
import Departure from '@/view/pages/experience/components/wizard/Departure';
import Title from '@/view/pages/experience/components/wizard/Title';
import Summary from '@/view/pages/experience/components/wizard/Summary';
import Provide from '@/view/pages/experience/components/wizard/Provide';
import Highlight from '@/view/pages/experience/components/wizard/Highlight';
import Include from '@/view/pages/experience/components/wizard/Include';
import Exclude from '@/view/pages/experience/components/wizard/Exclude';
import FullDescription from '@/view/pages/experience/components/wizard/FullDescription';
import Important from '@/view/pages/experience/components/wizard/Important';
import Photos from '@/view/pages/experience/components/wizard/Photos';
import MaxParticipant from '@/view/pages/experience/components/wizard/MaxParticipant';
import Participant from '@/view/pages/experience/components/wizard/Participant';
import Duration from '@/view/pages/experience/components/wizard/Duration';
import Price from '@/view/pages/experience/components/wizard/Price';
import Meeting from '@/view/pages/experience/components/wizard/Meeting';
import Submit from '@/view/pages/experience/components/wizard/Submit';

export default {
  name: 'CreateExperienceWizard',
  components: {
    Theme,
    ServiceType,
    Departure,
    Title,
    Summary,
    Provide,
    Highlight,
    Include,
    Exclude,
    FullDescription,
    Important,
    Photos,
    MaxParticipant,
    Participant,
    Duration,
    Price,
    Meeting,
    Submit
  },
  data() {
    return {
      wizard: null,
      activeStep: 1,
      experienceId: this.$route.params.id,
      steps: [
        {
          number: 1,
          title: 'Tema',
          state: 'current',
          type: 'step',
          ref: 'themeStep',
          isDone: ''
        },
        {
          number: 2,
          title: 'Hizmet tipi',
          state: 'pending',
          type: 'step',
          ref: 'serviceTypeStep',
          isDone: ''
        },
        {
          number: 3,
          title: 'Hareket yeri',
          state: 'pending',
          type: 'step',
          ref: 'departureStep',
          isDone: ''
        },
        {
          number: 4,
          title: 'Başlık',
          state: 'pending',
          type: 'step',
          ref: 'titleStep',
          isDone: ''
        },
        {
          number: 5,
          title: 'Özet',
          state: 'pending',
          type: 'step',
          ref: 'summaryStep',
          isDone: ''
        },
        {
          number: 6,
          title: 'Sunacaklarım',
          state: 'pending',
          type: 'step',
          ref: 'provideStep',
          isDone: ''
        },
        {
          number: 7,
          title: 'Öne çıkanlar',
          state: 'pending',
          type: 'step',
          ref: 'highlightStep',
          isDone: ''
        },
        {
          number: 8,
          title: 'Tam açıklama',
          state: 'pending',
          type: 'step',
          ref: 'fullDescriptionStep',
          isDone: ''
        },
        {
          number: 9,
          title: 'Neler dahil?',
          state: 'pending',
          type: 'step',
          ref: 'includeStep',
          isDone: ''
        },
        {
          number: 10,
          title: 'Neler dahil değil?',
          state: 'pending',
          type: 'step',
          ref: 'excludeStep',
          isDone: ''
        },
        {
          number: 11,
          title: 'Önemli bilgiler',
          state: 'pending',
          type: 'step',
          ref: 'importantStep',
          isDone: ''
        },
        {
          number: 12,
          title: 'Fotoğraflar',
          state: 'pending',
          type: 'step',
          ref: 'photosStep',
          isDone: ''
        },
        {
          number: 13,
          title: 'Katılımcı sayısı',
          state: 'pending',
          type: 'step',
          ref: 'maxParticipantStep',
          isDone: ''
        },
        {
          number: 14,
          title: 'Katılımcılar',
          state: 'pending',
          type: 'step',
          ref: 'participantStep',
          isDone: ''
        },
        {
          number: 15,
          title: 'Süre',
          state: 'pending',
          type: 'step',
          ref: 'durationStep',
          isDone: ''
        },
        {
          number: 16,
          title: 'Vitrin fiyatı',
          state: 'pending',
          type: 'step',
          ref: 'priceStep',
          isDone: ''
        },
        {
          number: 17,
          title: 'Buluşma',
          state: 'pending',
          type: 'step',
          ref: 'meetingStep',
          isDone: ''
        },        
        {
          number: 18,
          title: 'Deneyimi gönder',
          state: 'pending',
          type: 'step',
          ref: 'submitStep',
          isDone: ''
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["layoutConfig"]),
  },
  mounted() {
    this.getStatus();
  },
  methods: {
    initWizard() {
      const self = this;
      
      this.steps.forEach((el) => {
        if (el.number < self.activeStep) {
          el.isDone = 'checked';
        }
      });

      // Initialize form wizard
      this.wizard = new KTWizard('kt_wizard_experience', {
        startStep: self.activeStep, // initial active step number
        //clickableSteps: false, // allow step clicking
      });

      // Change event
      this.wizard.on('change', function(wizardObj) {
        if (wizardObj.newStep < wizardObj.currentStep) {
          self.activeStep -= 1; 
          return;
        }

        const step = wizardObj.getStep();
        const stepObj = self.steps.filter(function(el) {
          return el.number == step;
        })[0];

        if (!self.$refs[stepObj.ref].validate()) {
          wizardObj.stop();
          setTimeout(() => {
            KTUtil.scrollTop();
          }, 250);
        } else {
          self.setLastActiveStep(wizardObj.newStep);
          const index = step - 1;
          self.steps[index].isDone = 'checked';
          self.activeStep = wizardObj.newStep;
        }
      });
    },

    wizardNavClicked(step) {
      if (step.isDone == 'checked') {
        this.activeStep = step.number;
        this.wizard.goTo(step.number);
      }
    },
    goToLastActiveStep() {
      let step = sessionStorage.getItem('lastActiveStep');
      if (step == null) {
        step = 1;
      }
      this.steps.forEach((el) => {
        if (el.number < step) {
          el.isDone = 'checked';
        }
      });
      this.activeStep = step;
    },

    getLastActiveStep() {
      ApiService.get(`experience/step/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.activeStep = data.step;
        this.initWizard();
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    setLastActiveStep(newStep) {
      const data = {
        id: this.experienceId,
        step: newStep
      }

      ApiService.post('experience/step/set.req.php', data)
      .then(() => {})
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    getStatus() {
      ApiService.get(`experience/status/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        if (data.experienceStatus === 4 || data.experienceStatus === 5) {
          this.getLastActiveStep();
        } else {
          this.$router.push({ name: "show-experience", params: { id: this.experienceId } });
        }
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

  }
};
</script>

<style lang="scss">
@import '@/assets/sass/pages/wizard/wizard-2.scss';

.option {
  cursor: pointer;
  border-width: 2px;
}
.option .option-label .option-body {
  font-size: 1.08rem !important;
  max-width: 80% !important;
}
.option.selectable.selected,
.option.selectable:hover {
  border-color: var(--dark);
  background-color: var(--light);
}
.option.selected {
  border-width: 2px;
}
.wizard.wizard-2 .wizard-nav {
  flex: 0 0 300px;
  width: 300px;
}
.wizard.wizard-2 .wizard-nav .wizard-steps .wizard-step {
  border: 2px solid transparent;
  border-radius: 0.85rem;
}
.wizard.wizard-2
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state='current'] {
  border-color: var(--dark);
}
.wizard.wizard-2
  .wizard-nav
  .wizard-steps
  .wizard-step[data-wizard-state='current']:after {
  display: none;
}
#experience_builder_wizard_nav {
  position: static;
  height: 100vh;
  overflow-y: scroll;
}
#experience_builder_wizard_body {
  height: 100vh;
  overflow: scroll;
}
</style>
<style scoped>
.wizard-step {
  margin-left: -20px;
}
</style>
