<template>
  <div class="pb-5" :data-wizard-type="type" :data-wizard-state="state">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Misafirlerle nerede ve nasıl buluşacaksınız?
    </h1>
    <p class="font-size-h5 mb-7">
      Deneyiminize katılan misafirlerinizle otel, havalimanı ve/veya bir adreste buluşabilirsiniz.
    </p>

    <div class="alert alert-danger" role="alert" v-if="error.status">
      <div class="alert-text">{{ error.message }}</div>
    </div>

    <div class="d-flex flex-row align-items center">
      <label class="option selectable option-meeting-type mr-4" :class="{ selected: meetingAtHotel.status }"> 
        <span class="option-control">
          <span class="checkbox checkbox-dark checkbox-square">
            <input
              type="checkbox"
              name="meting_option"
              v-model="meetingAtHotel.status"
              @change="setHotelPickUpStatus"
            />
            <span></span>
          </span>
        </span>
        <span class="option-label">
          <span class="option-head">
            <span class="option-title">
              Otel
            </span>
            <span class="option-focus">
              <div
                class="spinner spinner-dark mr-4 mt-3"
                v-if="meetingAtHotel.isSaving"
              ></div>
            </span>
          </span>
        </span>
      </label>
      <label class="option selectable option-meeting-type mr-4" :class="{ selected: meetingAtAirport.status }">
        <span class="option-control">
          <span class="checkbox checkbox-dark checkbox-square">
            <input
              type="checkbox"
              name="meting_option"
              v-model="meetingAtAirport.status"
              @change="setAirportPickUpStatus"
            />
            <span></span>
          </span>
        </span>
        <span class="option-label">
          <span class="option-head">
            <span class="option-title">
              Havalimanı
            </span>
            <span class="option-focus">
              <div
                class="spinner spinner-dark mr-4 mt-3"
                v-if="meetingAtAirport.isSaving"
              ></div>
            </span>
          </span>
        </span>
      </label>
      <label class="option selectable option-meeting-type" :class="{ selected: meetingAtPoint.status }">
        <span class="option-control">
          <span class="checkbox checkbox-dark checkbox-square">
            <input
              type="checkbox"
              name="meting_option"
              v-model="meetingAtPoint.status"
              @change="setMeetingPointPickUpStatus"
            />
            <span></span>
          </span>
        </span>
        <span class="option-label">
          <span class="option-head">
            <span class="option-title" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
              Buluşma noktası
            </span>
            <span class="option-focus">
              <div
                class="spinner spinner-dark mr-4 mt-3"
                v-if="meetingAtPoint.isSaving"
              ></div>
            </span>
          </span>
        </span>
      </label>
    </div>

    <HotelPickUp :service-fee="!isDropOffAvailable" :experience-id="experienceId" v-if="meetingAtHotel.status && !meetingAtHotel.isSaving"></HotelPickUp>
    <AirportPickUp :service-fee="!isDropOffAvailable" :experience-id="experienceId" v-if="meetingAtAirport.status && !meetingAtAirport.isSaving"></AirportPickUp>
    <MeetingPointPickUp :experience-id="experienceId" v-if="meetingAtPoint.status && !meetingAtPoint.isSaving"></MeetingPointPickUp>

    <div v-if="isDropOffAvailable">
      <div class="separator separator-solid separator-border-2 my-20"></div>
      <h1 class="mb-5 font-weight-bolder text-dark display-4">
        Misafirlerinizin varış noktası neresi?
      </h1>
      <p class="font-size-h5 mb-7">
        Deneyiminize katılan misafirlerinizi otel, havalimanı veya bir adrese bırakabilirsiniz.
      </p>

      <div class="alert alert-danger" role="alert" v-if="dropOffError.status">
        <div class="alert-text">{{ dropOffError.message }}</div>
      </div>

      <div class="d-flex flex-row align-items center">
        <label class="option selectable option-meeting-type mr-4" v-for="(type, index) in dropOffTypes" :key="index" :class="{ selected: selectedDropOffOption == type.value }"> 
          <span class="option-control">
            <span class="radio radio-dark">
              <input
                type="radio"
                name="drop_off_option"
                :value="type.value"
                v-model="selectedDropOffOption"
                @change="dropOffOptionChanged"
              />
              <span></span>
            </span>
          </span>
          <span class="option-label">
            <span class="option-head">
              <span class="option-title">
                {{ type.title }}
              </span>
              <span class="option-focus">
                <div
                  class="spinner spinner-dark mr-4 mt-3"
                  v-if="selectedDropOffOption == type.value && dropOffOptionIsSaving"
                ></div>
              </span>
            </span>
          </span>
        </label>
      </div>

      <HotelDropOff :experience-id="experienceId" v-if="selectedDropOffOption == 1 && !dropOffOptionIsSaving"></HotelDropOff>
      <AirportDropOff :experience-id="experienceId" v-if="selectedDropOffOption == 2 && !dropOffOptionIsSaving"></AirportDropOff>
      <PointDropOff :experience-id="experienceId" v-if="selectedDropOffOption == 3 && !dropOffOptionIsSaving"></PointDropOff>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';
import HotelPickUp from '@/view/pages/experience/components/wizard/pickup/hotel/HotelPickUp';
import AirportPickUp from '@/view/pages/experience/components/wizard/pickup/airport/AirportPickUp';
import MeetingPointPickUp from '@/view/pages/experience/components/wizard/pickup/meeting-point/MeetingPointPickUp';

import HotelDropOff from '@/view/pages/experience/components/wizard/dropoff/hotel/HotelDropOff';
import AirportDropOff from '@/view/pages/experience/components/wizard/dropoff/airport/AirportDropOff';
import PointDropOff from '@/view/pages/experience/components/wizard/dropoff/point/PointDropOff';

export default {
  name: 'ExperienceWizardMeeting',
  props: ['experienceId', 'type', 'state'],
  components: {
    HotelPickUp,
    AirportPickUp,
    MeetingPointPickUp,
    HotelDropOff,
    AirportDropOff,
    PointDropOff
  },
  data() {
    return {
      meetingAtHotel: {
        status: false,
        isSaving: false
      },
      meetingAtAirport: {
        status: false,
        isSaving: false
      },
      meetingAtPoint: {
        status: false,
        isSaving: false
      },
      error: {
        status: false,
        message: '',
      },
      selectedDropOffOption: null,
      dropOffOptionIsSaving: false,

      dropOffTypes: [
        {
          title: 'Otel',
          value: 1
        },
        {
          title: 'Havalimanı',
          value: 2
        },
        {
          title: 'Bir Adres',
          value: 3
        }
      ],
      dropOffError: {
        status: false,
        message: '',
      },

      isDropOffAvailable: false
    }
  },
  mounted() {
    this.getTheme();
  },
  methods: {
    getTheme() {
      ApiService.get(`experience/theme/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.isDropOffAvailable = data.theme == 5;
        this.getStatus();
        if (this.isDropOffAvailable) {
          this.getDropOffType();
        }
      })
      .catch(({ response }) => {
        alert(response.data.errorMessage);
      });
    },

    getStatus() {
      ApiService.get(`experience/meeting/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.meetingAtHotel.status = data.hotelPickUp === 1;
        this.meetingAtAirport.status = data.airportPickUp === 1;
        this.meetingAtPoint.status = data.meetingPointPickUp === 1;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },
    

    setHotelPickUpStatus() {
      this.meetingAtHotel.isSaving = true;
      const data = {
        id: this.experienceId,
        status: this.meetingAtHotel.status ? 1 : 0
      }

      ApiService.post('experience/meeting/hotel-pickup/status/set.req.php', data)
      .then(() => {
        this.meetingAtHotel.isSaving = false;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.meetingAtHotel.status = !this.meetingAtHotel.status;
        this.meetingAtHotel.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    setAirportPickUpStatus() {
      this.meetingAtAirport.isSaving = true;
      const data = {
        id: this.experienceId,
        status: this.meetingAtAirport.status ? 1 : 0
      }

      ApiService.post('experience/meeting/airport-pickup/set-status.req.php', data)
      .then(() => {
        this.meetingAtAirport.isSaving = false;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.meetingAtAirport.status = !this.meetingAtAirport.status;
        this.meetingAtAirport.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    setMeetingPointPickUpStatus() {
      this.meetingAtPoint.isSaving = true;
      const data = {
        id: this.experienceId,
        status: this.meetingAtPoint.status ? 1 : 0
      }

      ApiService.post('experience/meeting/meeting-point-pickup/set-status.req.php', data)
      .then(() => {
        this.meetingAtPoint.isSaving = false;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.meetingAtPoint.status = !this.meetingAtPoint.status;
        this.meetingAtPoint.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    getDropOffType() {
      ApiService.get(`experience/drop-off/type/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.selectedDropOffOption = data.type;
        this.dropOffError.status = false;
      })
      .catch(({ response }) => {
        this.dropOffError.message = response.data.errorMessage;
        this.dropOffError.status = true;
      });
    },

    setDropOffType() {
      this.dropOffOptionIsSaving = true;
      const data = {
        pid: this.experienceId,
        type: this.selectedDropOffOption
      }

      ApiService.post('experience/drop-off/type/set.req.php', data)
      .then(() => {
        this.dropOffOptionIsSaving = false;
        this.dropOffError.status = false;
      })
      .catch(({ response }) => {
        this.selectedDropOffOption = 0;
        this.dropOffOptionIsSaving = false;
        this.dropOffError.message = response.data.errorMessage;
        this.dropOffError.status = true;
      });
    },

    dropOffOptionChanged() {
      this.setDropOffType();
    },


    validate() {
      return true;
    }

  }
}
</script>

<style scoped>
.option-meeting-type {
  flex: 1 1 33%;
}

</style>