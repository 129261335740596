<template>
  <div class="pb-5" :data-wizard-type="type" :data-wizard-state="state">
    <h1 class="mb-5 font-weight-bolder text-dark display-4">
      Deneyiminize bir başlık verin
    </h1>
    <p class="font-size-h5 mb-7">
      Kısa, açıklayıcı ve heyecan verici olmasını sağlayın.
    </p>

    <div class="alert alert-warning bg-light-warning my-8" role="alert" v-if="error.status">
      <div class="alert-text text-body d-flex align-items-center">
        <span class="svg-icon svg-icon-warning pr-2">
          <inline-svg src="media/svg/icons/Code/Warning-1-circle.svg" />
        </span>
        <span class="font-weight-bold">{{ error.message }}</span>
      </div>
    </div>

    <div class="form-group mb-10">
      <label class="text-body font-weight-bold pl-1">Türkçe başlık</label>
      <div class="input-group input-group-lg input-group-solid">
        <input
          type="text"
          class="form-control font-size-lg"
          :maxlength="maxTitleLength"
          v-model.trim="title.tr"
          ref="inputTitleTR"
          @input="handleInputChange"
        />
        <div class="input-group-append">
          <div
              class="spinner spinner-dark mr-11"
              v-if="isSaving && lastChangedInput === 'tr' "
          ></div>
          <span
            v-else
            class="input-group-text font-weight-bold"
            :class="[remainingCharsTR > 0 ? 'text-success' : 'text-dark']"
            >{{ remainingCharsTR }}</span
          >
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="text-body font-weight-bold pl-1">İngilizce başlık</label>
      <div class="input-group input-group-lg input-group-solid">
        <input
          type="text"
          class="form-control font-size-lg"
          :maxlength="maxTitleLength"
          v-model.trim="title.en"
          ref="inputTitleEN"
          @input="handleInputChange"
        />
        <div class="input-group-append">
          <div
              class="spinner spinner-dark mr-11"
              v-if="isSaving && lastChangedInput === 'en' "
          ></div>
          <span
            v-else
            class="input-group-text font-weight-bold"
            :class="[remainingCharsEN > 0 ? 'text-success' : 'text-dark']"
            >{{ remainingCharsEN }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service';

export default {
  name: 'ExperienceWizardTitle',
  props: ['experienceId', 'type', 'state'],
  data() {
    return {
      title: {
        tr: '',
        en: '',
      },
      error: {
        status: false,
        message: 'Devam edebilmek için lütfen deneyiminize bir başlık ekleyin.',
      },
      isSaving: false,
      maxTitleLength: 60,
      timeout: null,
      lastChangedInput: ''
    };
  },
  computed: {
    remainingCharsTR() {
      return this.maxTitleLength - this.title.tr.length;
    },
    remainingCharsEN() {
      return this.maxTitleLength - this.title.en.length;
    },
  },
  watch: {
    'title.tr': function() {
      this.lastChangedInput = 'tr';
    },
    'title.en': function() {
      this.lastChangedInput = 'en';
    }
  },
  mounted() {
    this.getTitle();
  },
  methods: {
    getTitle() {
      ApiService.get(`experience/title/get.req.php?id=${this.experienceId}`)
      .then(({ data }) => {
        this.title.tr = data.title.tr;
        this.title.en = data.title.en;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    setTitle() {
      this.isSaving = true;
      const data = JSON.stringify({
        id: this.experienceId,
        title: JSON.stringify(this.title)
      });

      ApiService.post('experience/title/set.req.php', data)
      .then(() => {
        this.isSaving = false;
        this.error.status = false;
      })
      .catch(({ response }) => {
        this.isSaving = false;
        this.error.message = response.data.errorMessage;
        this.error.status = true;
      });
    },

    handleInputChange() {
      const self = this;
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        self.setTitle();
      }, 1000);
    },

    validate() {
      if (this.title.tr === '' || this.title.en === '') {
        this.error.status = true;
        return false;
      } else {
        this.error.status = false;
        return true;
      }
    },
  },
};
</script>

<style scoped>
.form-group input[type='text'] {
  height: 60px;
}
</style>
